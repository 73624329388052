<template lang="pug">
	.d-flex.flex-column.edit-project-link
		.d-flex.position-relative.edit-project-link__control(:class="{'is-focused': isFocused, 'is-error': sendAttempt && $v.form.address.$error}")
			.fontello.fontello-icon-link.edit-project-link__icon
			.d-flex.w-100.edit-project-link__input
				span.b3.text-grey.edit-project-link__domian {{ paymentLinkDomain }}
				input.edit-project-link__address(
					v-model="$v.form.address.$model"
					id="edit-project-link"
					@focus="isFocused = true"
					@blur="isFocused = false"
				)
			label.m-0.b4.edit-project-link__label(for="edit-project-link") {{ $t('errors.required.address') }}
			icon-close.edit-project-link__reset(@click="$v.form.address.$model = ''")
		.b4.mt-1
			.text-danger(v-if='sendAttempt && !$v.form.address.required') {{ $t('errors.required.common' )}}
			.text-danger(v-else-if='sendAttempt && !$v.form.address.minLength') {{ $t('errors.minLength.hashId' )}}
			.text-danger(v-else-if='sendAttempt && !$v.form.address.maxLength') {{ $t('errors.maxLength.hashId' )}}
			.text-danger(v-else-if='sendAttempt && !$v.form.address.maxLength') {{ $t('errors.maxLength.hashId' )}}
			.text-danger(v-else-if='sendAttempt && !$v.form.address.mustBeCool') {{ $t('errors.alpha.hashId' )}}
			.text-danger(v-else-if='sendAttempt && error') {{ error }}
		.btn.btn-primary.mt-3(v-if='item' :class="{'disabled': $v.form.address.$model == item.hashIdCustom || isLoading}" @click='validateProjectAddress')
			.loader(v-if="isLoading")
			span(v-else) {{ $t('forms.save') }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import IconClose from '@/components/Common/IconClose';

const { required, minLength, maxLength } = require('vuelidate/lib/validators');
const mustBeCool = value => value && value.match(/[^a-zA-Z_0-9]/gi) == null;

export default {
    name: 'EditProjectLink',
    components: {
        IconClose,
    },
    props: {
        item: {
            type: Object,
            default: () => null,
        },
    },
    data: () => ({
        error: null,
        sendAttempt: false,
        form: {
            address: null,
        },
        isFocused: null,
        isLoading: false,
    }),
    validations() {
        return {
            form: {
                address: {
                    required,
                    mustBeCool,
                    minLength: minLength(5),
                    maxLength: maxLength(32),
                },
            },
        };
    },
    methods: {
        ...mapActions({
            edit: 'project/edit',
        }),
        validateProjectAddress(a) {
            this.$v.$touch();
            this.sendAttempt = true;
            if (this.$v.form.$anyError) return false;

            this.error = null;
            let value = this.form.address.replace(this.paymentLinkDomain, '');

            this.isLoading = true;

            return this.edit({ id: this.item.id, hashIdCustom: value })
                .then(v => {
                    this.item.hashIdCustom = value;
                    this.$notify('success', this.$t('success.editLink'));
                    this.isLoading = false;
                    this.close();
                })
                .catch(e => {
                    this.error = e;
                    this.$notify('error', this.$t('errors.editLink'), e);
                    this.isLoading = false;
                });
        },
        close() {
            this.form.address = this.item.hashIdCustom;
            this.$emit('close');
            this.sendAttempt = false;
        },
    },
    computed: {
        paymentLinkDefault() {
            return this.item ? `${this.paymentLinkDomain}${this.item.hashId}` : '';
        },
        paymentLinkDomain() {
            return `${window.location.protocol}//${window.location.host.replace('merchant.', '')}/`;
        },
    },
    watch: {
        item: {
            handler(nV, oV) {
                this.$v.$reset();
                this.error = null;
                this.sendAttempt = false;

                if (nV != null && nV.id) {
                    this.form.address = this.item.hashIdCustom;
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.edit-project-link {
    &__control {
        background-color: var(--grey-bg-color);
        border-radius: var(--border-radius-rounded);

        &.is-focused {
            background-color: var(--background-color);
        }

        &.is-error {
            background-color: transparent;

            .edit-project-link__input {
                border: 1.5px solid var(--red-bg-color) !important;

                &:hover,
                &:focus {
                    border: 1px solid var(--red-bg-color) !important;
                }
            }

            &::before {
                background-color: var(--red-text-color);
            }
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 21px;
        font-size: 20px;
        color: var(--brand-primary);
        transform: translateY(-50%);
        cursor: text;
    }

    &__input {
        z-index: 1;
        align-items: center;
        height: 52px;
        padding: 24px 40px 6px 50px;
        border: 1.5px solid transparent;
        background-color: transparent;
        border-radius: var(--border-radius-rounded);
        transition: var(--animation-time-short);
        cursor: text;

        &:hover {
            background-color: transparent !important;
            border: 1.5px solid var(--button-border-color) !important;
        }

        &:focus {
            background-color: transparent !important;
            border: 1.5px solid var(--button-border-color) !important;
        }
    }

    &__label {
        position: absolute;
        top: 30%;
        left: 50px;
        color: var(--grey-text-color);
        transform: translateY(-50%);
        cursor: text;
        transition: var(--animation-time-short);
    }

    &__address {
        width: 100%;
        color: var(--primary-color);
        border: none;
        background: transparent;
        outline: none;
        padding: 0;

        &:focus {
            background: transparent;
        }
    }

    &__reset {
        z-index: 1;
        position: absolute;
        top: 50%;
        right: 19px;
        transform: translateY(-50%);
        transition: var(--animation-time-short);

        &:hover,
        &:active {
            opacity: 0.7;
        }
    }
}
</style>
