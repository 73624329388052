<template lang="pug">
	b-modal(
		ref='modal'
		id="show-qr-code"
		hide-footer
		hide-header
		centered
		content-class="modal-general__content my-0 mx-auto"
		:scrollable="isMobile"
		:body-class="['d-flex flex-column']"
		:modal-class="{'modal-qr-code': true, 'is-neurobot': isNeurobot || isPartners}"
	)
		.modal-qr-code__background(v-if="!isNeurobot" :style="`background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${projectImage}) center / cover no-repeat`")
		.modal-qr-code__background(v-if="isNeurobot && projectImage" :style="`background: url(${projectImage}) center / cover no-repeat`")
		.modal-qr-code__background(v-if="isNeurobot && !projectImage" :style="`background: url('/assets/img/neurobots/noAvatar.webp') center / cover no-repeat`")

		.modal-qr-code__content
			svg.cursor-pointer.modal-qr-code__go-back(v-if="isMobile" @click="close()" width='24' height='24' viewbox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
				path(d='M14.9998 19.9201L8.47984 13.4001C7.70984 12.6301 7.70984 11.3701 8.47984 10.6001L14.9998 4.08008' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')

			icon-close.modal-qr-code__close(v-if="!isMobile" @click="close()" :class="{'is-no-avatar': isNeurobot && !projectImage}")

			.modal-qr-code__empty(v-if="isMobile && isNeurobot")

			.modal-qr-code__wrap
				img.d-block.modal-qr-code__image(v-if="!isNeurobot && !isPartners" :src="`${projectImage}`" width="278" height="153")
				img.d-block.modal-qr-code__avatar(v-if="isNeurobot && projectImage || isPartners && projectImage" :src="`${projectImage}`" width="101" height="101")
				.modal-qr-code__avatar.is-no-avatar(v-if="isNeurobot && !projectImage")
					span.text-primary {{ `${projectName.replace(/[^a-zA-Zа-яА-Я0-9]/g, '').slice(0, 1)}` }}

				.border-rounded.modal-qr-code__inner
					img.d-block.modal-qr-code__qr(:src='qrPaymentPage' alt='QR code payment' width="150" height='150')
					span.d-block.modal-qr-code__name(v-if="projectName") {{ projectName }}

			.modal-qr-code__bottom
				.btn.btn-primary.modal-qr-code__share(@click="shareLink") {{ $t('project.share') }}
				a.btn.btn-primary.modal-qr-code__download(:href="qrPaymentPage" :download="`${projectName}.jpg`") {{ $t('project.downloadQRCode') }}

</template>

<script>
import { mapGetters } from 'vuex';
import IconClose from '@/components/Common/IconClose';

export default {
    name: 'ModalQRCode',
    components: {
        IconClose,
    },
    props: {
        projectImage: {
            default: '',
            type: String,
        },
        projectName: {
            default: '',
            type: String,
        },
        qrPaymentPage: {
            default: '',
            type: String,
        },
        isNeurobot: {
            default: false,
            type: Boolean,
        },
        isPartners: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            item: 'project/opened',
        }),
        paymentLink() {
            return this.item ? `${this.paymentLinkDomain}${this.item.hashIdCustom}` : '';
        },
        paymentLinkDefault() {
            if (!this.item) return '';

            if (this.item.hashIdCustom != this.item.hashId) return this.paymentLink;

            return this.item ? `${this.paymentLinkDomain}${this.item.hashId}` : '';
        },
        paymentLinkDomain() {
            return `${window.location.protocol}//${window.location.host.replace('merchant.', '')}/`;
        },
    },
    methods: {
        close() {
            this.$refs.modal.hide();
        },
        async shareLink() {
            let img = new Image();
            let base64String = this.qrPaymentPage.replace('data:application/octet-stream', 'data:image/jpeg');
            img.src = base64String;
            const blob = await fetch(img.src).then(res => res.blob());
            const file = new File([blob], `${this.projectName}.jpg`, { type: 'image/jpeg' });

            if (navigator.share) {
                await navigator.share({
                    files: [file],
                    title: this.projectName,
                    text: this.$t('project.share'),
                    url: this.paymentLinkDefault,
                });
            }
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.modal-qr-code {
    .modal-dialog {
        max-width: 462px;

        @include respond-below(sm-new) {
            min-height: 100%;
        }
    }

    .modal-content {
        box-shadow: var(--card-box-shadow) !important;
        overflow: hidden;
        padding: 0;
    }

    .modal-body {
        padding: 0;
        position: relative;
        width: 100%;
        min-height: 594px;

        @include respond-below(sm-new) {
            min-height: 100%;
        }

        &::before {
            position: absolute;
            transform: translate3d(0, 0, 0);
            backdrop-filter: blur(150px);
            content: '';
            z-index: 1;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.4);
        }
    }

    &__background {
        position: absolute;
        z-index: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto;
        height: auto;
        filter: none;
        transform: none;
        margin: 0;
    }

    &__content {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include respond-below(sm-new) {
            /* Скрываем scrollbar для IE, Edge и Firefox */
            -ms-overflow-style: none; /* IE и Edge */
            scrollbar-width: none; /* Firefox */
            position: absolute;
            z-index: 2;

            /* Скрываем scrollbar для Chrome, Safari и Opera */
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__wrap {
        @include respond-below(sm-new) {
            margin: 0 17.5px;
        }
    }

    &__go-back {
        position: absolute;
        top: 26px;
        left: 20px;
        z-index: 2;
    }

    &__close {
        position: absolute;
        top: 28px;
        right: 39px;
        z-index: 2;

        &.is-no-avatar {
            path {
                stroke: var(--grayscale-close-icon);
            }
        }
    }

    &__image {
        margin: 73px auto 0;
        position: relative;
        z-index: 3;
        border-radius: 6px;
        object-fit: cover;

        @include respond-below(sm-new) {
            margin-top: 74px;
            width: 276px;
            height: 150px;
        }

        @media (max-width: 375px) {
            width: 100%;
        }
    }

    &__avatar {
        position: relative;
        z-index: 3;
        border-radius: 50%;
        margin: 48px auto 0;
        width: 101px;
        height: 101px;

        &.is-no-avatar {
            border: 1px solid var(--grayscale-dark-line);
            border-radius: 50%;
            background-color: var(--primary-bg-color);
            font-weight: 600;
            font-size: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @include respond-below(sm-new) {
            width: 95px;
            height: 95px;
            margin-top: 60px;
        }
    }

    &__inner {
        width: 218px;
        margin: -18px auto 45px;
        background-color: var(--foreground-color);
        box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.08);
        padding: 28px 15px 24px;
        position: relative;
        z-index: 2;

        @include respond-below(sm-new) {
            margin-bottom: 26px;
            padding-top: 39px;
            padding-bottom: 24px;
            width: 244px;
        }
    }

    &__qr {
        margin: 0 auto 20.5px;

        @include respond-below(sm-new) {
            margin-bottom: 15px;
            width: 176px;
            height: 176px;
        }
    }

    &__name {
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        word-break: break-word;
    }

    &__bottom {
        background-color: var(--brand-qr-bottom);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 21px 15px 24px;
        width: 100%;
        position: relative;
        z-index: 2;

        @include respond-below(sm-new) {
            padding: 20.5px 15px 40px;
        }
    }

    &__share.btn {
        max-width: 335px;
        margin: 0 auto;
        height: 45px;
        color: var(--foreground-color);
        display: none;

        @include respond-below(sm-new) {
            display: block;
        }
    }

    &__download {
        max-width: 340px;
        margin: 0 auto;
        height: 45px;
        color: var(--foreground-color);
        display: flex;
        padding: 0;
        align-items: center;

        @include respond-below(sm-new) {
            display: none;
        }

        &:hover,
        &:active,
        &:focus {
            border: 0;
            outline: none;
            color: var(--foreground-color) !important;
        }

        &:focus {
            box-shadow: none !important;
        }
    }

    &.is-neurobot {
        .modal-dialog {
            max-width: 442px;
        }

        .modal-body {
            min-height: 525px;

            @include respond-below(sm-new) {
                min-height: 100%;
            }

            &::before {
                background-color: rgba(0, 0, 0, 0.06);
            }
        }

        .modal-qr-code {
            &__inner {
                margin-top: -15.5px;
                padding-top: 33.5px;

                @include respond-below(sm-new) {
                    margin-bottom: 100px;
                }
            }
        }
    }
}
</style>
